// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-nizza-klasse-tsx": () => import("./../../../src/templates/nizza-klasse.tsx" /* webpackChunkName: "component---src-templates-nizza-klasse-tsx" */),
  "component---src-templates-nizza-produkt-tsx": () => import("./../../../src/templates/nizza-produkt.tsx" /* webpackChunkName: "component---src-templates-nizza-produkt-tsx" */),
  "component---src-templates-nizza-typ-tsx": () => import("./../../../src/templates/nizza-typ.tsx" /* webpackChunkName: "component---src-templates-nizza-typ-tsx" */)
}

